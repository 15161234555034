import React from "react"
import { Link } from "gatsby"
import Image from "../../components/image"
import Layout from "../../components/layout"
// import SEO from "../components/seo"
import "../../assets/sass/hardscaping.sass"

const SnowPlowing = () => (
    
    <div class="info-page hardscaping">

        <div class="info-page-intro">
            <h1>Hardscaping</h1>
            <p>Is Your Home Looking A Little Shabby Around This Time Of Year?</p>
        </div>
        <div className="services-container">
            <div className="hardscape-service">
                <div className="service-image">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.11.08.jpg?alt=media&token=c8a94bb7-e7b0-4e1b-9bf8-3a878170ca56" />
                </div>
                <h2>Pavers</h2>
                <Link to="/pavers">Read More</Link>
            </div>
            <div className="hardscape-service">
                <div className="service-image">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.10.44.jpg?alt=media&token=aa16046c-c013-4954-9ae0-349b498dcbea" />
                </div>
                <h2>Patios</h2>
                <Link to="/patios">Read More</Link>
            </div>
        </div>
        <div class="fall-discription">
            <p>
            Patios, walkways, brick and concrete driveways and terraces installed using brick, natural stone and manufactured paversporches, stoops and steps, seat walls, fireplaces, 
            fire pits and built-in grills installed using brick and stone masonrypergolas, trellises, decorative fencing, wooden screens and decks installed by custom carpenters
            </p>
            <p>
            Here at Kevin's landscaping, we do whatever is needed to get your property ready for the next season.
            We have years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
            </p>

        </div>

    </div>

)

export default SnowPlowing